// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// DIBK Design
import { Header, Button, RadioButtonListItem, Paper } from 'dibk-design';

// Actions
import { convertAttachment } from 'actions/AttachmentActions';
import { updateSelectedNextProcessCategory } from 'actions/NextProcessCategoryActions';
import { updateWizardSteps } from 'actions/WizardStepsActions';

// Stylesheets
import commonStyle from 'components/routes/Wizard/common.module.scss';

class NextProcessCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNextProcessCategory: null
        }
        this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
        this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
    }

    handleNextButtonClick() {
        if (this.props.wizardSteps && this.props.wizardSteps['NextProcessCategory']) {
            let wizardSteps = { ...this.props.wizardSteps };
            wizardSteps['NextProcessCategory'].finished = true;
            this.props.updateWizardSteps(wizardSteps);
        }
    }

    handlePreviousButtonClick() {
        if (this.props.wizardSteps && this.props.wizardSteps['Import']) {
            let wizardSteps = { ...this.props.wizardSteps };
            wizardSteps['Import'].finished = false;
            this.props.updateWizardSteps(wizardSteps);
        }
    }

    handleRadioButtonChange(event) {
        const nextProcessCategory = event.target.value;
        this.props.updateSelectedNextProcessCategory(nextProcessCategory);
        const attachment = this.props.attachment;
        if (attachment) {
            this.props.convertAttachment(attachment);
        }

        this.setState({
            selectedNextProcessCategory: event.target.value
        }, () => {

        });
    }

    renderRadioButtonList(processCategories) {
        return processCategories && processCategories.length
            ? processCategories.map(processCategory => {
                return (<RadioButtonListItem onChange={(event) => { this.handleRadioButtonChange(event) }} key={processCategory.id} id={processCategory.id} inputValue={processCategory.id} name="nextStep" checked={this.state.selectedNextProcessCategory === processCategory.id}>
                    {processCategory.name}
                </RadioButtonListItem>);
            }) : '';
    }

    render() {

        return this.props.wizardSteps ? (<div>
            <Header content="Velg søknadstype" />
            <Paper>
                <div className={commonStyle.introText}>
                    <p>Her velger du den søknadstypen du vil konvertere til.</p>
                    <p>For at konverteringen skal fungere må du ha sendt inn søknad om rammetillatelse, søknad i ett trinn eller søknad om tiltak uten ansvarsrett til kommunen.</p>
                </div>
                {this.renderRadioButtonList(this.props.processCategories)}
            </Paper>
            <div className={commonStyle.buttonRow}>
                <Link to={{pathname: 'Import', search: window.location.search}}>
                    <Button color="primary" content="Forrige" arrow='left' onClick={this.handlePreviousButtonClick} />
                </Link>
                <Link to={{pathname: 'Receipt', search: window.location.search}}>
                    <Button color="primary" disabled={this.state.selectedNextProcessCategory ? '' : 'disabled'} content="Konverter søknad" arrow='right' onClick={this.handleNextButtonClick} />
                </Link>
            </div>
        </div>) : ''
    }
}

const mapStateToProps = state => ({
    selectedNextProcessCategory: state.selectedNextProcessCategory,
    attachment: state.attachment,
    wizardSteps: state.wizardSteps,
    processCategories: state.processCategories
});

const mapDispatchToProps = {
    convertAttachment,
    updateSelectedNextProcessCategory,
    updateWizardSteps
};

export default connect(mapStateToProps, mapDispatchToProps)(NextProcessCategory);
