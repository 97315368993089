// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';


export const postReceiptEmail = body => (dispatch, getState) => {
    const apiUrl = `${getEnvironmentVariable('byggsokApi')}/SendEmail`;

    const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl, fetchOptions);
};

export const getProjectAddress = subForm => (dispatch, getState) => {
    const apiUrl = `${getEnvironmentVariable('byggsokApi')}/getProjectAddress`;

    const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(subForm),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl, fetchOptions)
    .then(res => res.text())
    .then(projectAddress => {
        return projectAddress;
    });
};
