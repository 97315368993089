// Dependencies
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faChevronUp, faPlus, faMinus, faTimes, faChevronLeft, faChevronRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

// Utils
import configureStore, { history } from 'utils/configureStore';

// Components
import NotFound from 'components/routes/NotFound';
import Wizard from 'components/routes/Wizard'
import ReporteeInfo from 'components/partials/ReporteeInfo'

// DIBK design
import { NavigationBar } from 'dibk-design';

// Stylesheets
import style from 'App.module.scss';

library.add(fab, faQuestionCircle, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faPlus, faMinus, faTimes)

const initialState = {};
const store = configureStore(initialState);

class App extends Component {
  render() {
    return (<Provider store={store}>
      <ConnectedRouter history={history}>
        <NavigationBar logoLink="/">
          <div className={style.navigationBarText}>
            <span>Har du spørsmål?</span>
            <span>Ta kontakt på e-post: <a href="mailto:ftb@dibk.no">ftb@dibk.no</a></span>
          </div>
        </NavigationBar>
        <div className={style.container}>
          <ReporteeInfo />
          <Switch>
            <Route exact={true} path="/:activeStepId" render={(props) => (<Wizard {...props} />)} />
            <Route exact={true} path="/" render={(props) => (<Wizard {...props} />)} />
            <Route render={() => (<NotFound />)} />
          </Switch>
        </div>
      </ConnectedRouter>
    </Provider>);
  }
}

export default App;
