// Helpers
import {getEnvironmentVariable} from 'helpers/environmentVariableHelpers.js';

// Constants
import {FETCH_AVAILABLE_REPORTEES, UPDATE_SELECTED_REPORTEE} from 'constants/types';


export const fetchAvailableReportees = () => dispatch => {
  const apiUrl = `${getEnvironmentVariable('altinnApi')}/reportees?showConsentReportees=false`;
  const fetchOptions = {
    method: 'GET',
    headers: {
      'ApiKey': getEnvironmentVariable('altinnApiKey'),
      'Accept': 'application/hal+json'
    },
    xhrFields: {
      withCredentials: true
    },
    credentials: "include"
  };
  return fetch(apiUrl, fetchOptions).then(res => res.json()).then(availableReportees => {
    if (availableReportees && availableReportees._embedded && availableReportees._embedded.reportees) {
      dispatch({type: FETCH_AVAILABLE_REPORTEES, payload: availableReportees._embedded.reportees})
    }
  });
};

export const updateSelectedReportee = selectedReportee => dispatch => {
  dispatch({
    type: UPDATE_SELECTED_REPORTEE,
    payload: selectedReportee
  });
}
