// Dependencies
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

// Reducers
import AttachmentReducer from 'reducers/AttachmentReducer';
import AvailableReporteesReducer from 'reducers/AvailableReporteesReducer';
import ConvertedAttachmentReducer from 'reducers/ConvertedAttachmentReducer';
import MessageIdReducer from 'reducers/MessageIdReducer';
import ProcessCategoriesReducer from 'reducers/ProcessCategoriesReducer';
import SelectedNextProcessCategoryReducer from 'reducers/SelectedNextProcessCategoryReducer';
import SelectedReporteeReducer from 'reducers/SelectedReporteeReducer';
import WizardStepsReducer from 'reducers/WizardStepsReducer';

export default(history) => combineReducers({
  router: connectRouter(history),
  attachment: AttachmentReducer,
  availableReportees: AvailableReporteesReducer,
  convertedAttachment: ConvertedAttachmentReducer,
  messageId: MessageIdReducer,
  processCategories: ProcessCategoriesReducer,
  selectedNextProcessCategory: SelectedNextProcessCategoryReducer,
  selectedReportee: SelectedReporteeReducer,
  wizardSteps: WizardStepsReducer
});
