// Dependencies
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// DIBK Design
import {Header, Button, Paper, RadioButtonListItem} from 'dibk-design';

// Actions
import {fetchAvailableReportees, updateSelectedReportee} from 'actions/ReporteesActions';
import {updateWizardSteps} from 'actions/WizardStepsActions';

// Assets
import foretakImage from 'assets/images/foretak.png';
import personImage from 'assets/images/person.png';

// Stylesheets
import commonStyle from 'components/routes/Wizard/common.module.scss';

class AvailableReportees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReporteeId: null,
      previousStepHasErrors: false
    }
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchAvailableReportees().then(() => {
      let wizardSteps = {
        ...this.props.wizardSteps
      };
      if (wizardSteps && wizardSteps['SignIn']) {
        if (this.props.availableReportees && this.props.availableReportees.length) {
          wizardSteps['SignIn'] = {
            ...wizardSteps['SignIn'],
            finished: true,
            hasErrors: false
          };
        } else {
          wizardSteps['SignIn'] = {
            ...wizardSteps['SignIn'],
            finished: false,
            hasErrors: false
          };
          this.setState({previousStepHasErrors: true});
        }
        this.props.updateWizardSteps(wizardSteps);
      }
    });
  }

  handleNextButtonClick() {
    if (this.props.wizardSteps && this.props.wizardSteps['AvailableReportees']) {
      let wizardSteps = {
        ...this.props.wizardSteps
      };
      wizardSteps['AvailableReportees'].finished = true;
      this.props.updateWizardSteps(wizardSteps);
    }
  }

  handlePreviousButtonClick() {
    if (this.props.wizardSteps && this.props.wizardSteps['SignIn']) {
      let wizardSteps = {
        ...this.props.wizardSteps
      };
      wizardSteps['SignIn'].finished = false;
      this.props.updateWizardSteps(wizardSteps);
    }
  }

  getSelectedReporteeById(selectedReporteeId) {
    return this.props.availableReportees.find(availableReportee => {
      return availableReportee.ReporteeId === selectedReporteeId;
    });
  }

  getReporteeImageUrl(reportee) {
    switch (reportee.Type){
      case 'Enterprise':
      case 'Foretak':
      case 'Føretak':
        return foretakImage;
      case 'Business':
      case 'Bedrift':
        return foretakImage;
      case 'Person':
        return personImage;
      default:
        return '';
    }
  }

  handleRadioButtonChange(event) {
    const selectedReporteeId = event.target.value;
    const selectedReportee = this.getSelectedReporteeById(selectedReporteeId);
    this.props.updateSelectedReportee(selectedReportee);
    this.setState({selectedReporteeId: event.target.value});
  }

  sortReportees(reporteesArray) {
    return reporteesArray.sort(function (a, b) {
        if (a.Type < b.Type) {
            return 1;
        }
        if (a.Type > b.Type) {
            return -1;
        }
        return 0;
    });
  }

  renderRadioButtonList(availableReportees) {
    return availableReportees && availableReportees.length
      ? this.sortReportees(availableReportees).map(availableReportee => {
        return (<div className={commonStyle.radioButtonFlexLabel} key={availableReportee.ReporteeId}>
          <RadioButtonListItem onChange={(event) => {
              this.handleRadioButtonChange(event)
            }} id={availableReportee.ReporteeId} inputValue={availableReportee.ReporteeId} name="nextStep" checked={this.state.selectedReporteeId === availableReportee.ReporteeId}>
            <div className={commonStyle.radioButtonLabelContainer}>
              <div className={commonStyle.radioButtonLabelText}>
                <span>{availableReportee.Name}</span>
                { availableReportee.OrganizationNumber 
                  ? (
                    <span className={commonStyle.subTitle}>Orgnr. {availableReportee.OrganizationNumber}</span>
                  )
                  : ''
                }
              </div>
              <img className={commonStyle.radioButtonLabelImage} alt="Illustrasjon av representanttype" src={this.getReporteeImageUrl(availableReportee)}/>
            </div>
          </RadioButtonListItem>
        </div>);
      })
      : '';
  }

  render() {
    return this.state.previousStepHasErrors
      ? (<div>
        <Header content="Neste søknadstype"/>
        <Paper>
          <div className={commonStyle.introText}>
            <p>Henting av representasjonsrettigheter feilet</p>
          </div>
        </Paper>
      </div>)
      : (<div>
        <Header content="Velg hvem du representerer"/>
        <Paper>
          <div className={commonStyle.introText}>
            <p>Velg det foretaket eller firmaet du representerer i byggesaken.</p>
            <p>Den du velger blir avsender av søknaden når den sendes fra søknadssystemet.</p>
          </div>
          {this.renderRadioButtonList(this.props.availableReportees)}
        </Paper>
        <div className={commonStyle.buttonRow}>
          <Link to={{pathname: 'SignIn', search: window.location.search}}>
            <Button color="primary" content="Forrige" arrow='left' onClick={this.handlePreviousButtonClick}/>
          </Link>
          <Link to={{pathname: 'Import', search: window.location.search}}>
            <Button color="primary" disabled={this.state.selectedReporteeId ? '' : 'disabled'} content="Neste" arrow='right' onClick={this.handleNextButtonClick}/>
          </Link>
        </div>
      </div>)
  }
}

const mapStateToProps = state => ({availableReportees: state.availableReportees, wizardSteps: state.wizardSteps});

const mapDispatchToProps = {
  fetchAvailableReportees,
  updateSelectedReportee,
  updateWizardSteps
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableReportees);
