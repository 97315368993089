import { INIT_WIZARD_STEPS, UPDATE_WIZARD_STEP } from 'constants/types';

const initialState = {
    SignIn: {
        id: 'SignIn',
        name: 'Start',
        finished: false,
        hasErrors: false
    },
    AvailableReportees: {
        id: 'AvailableReportees',
        name: 'Velg hvem du representerer',
        finished: false,
        hasErrors: false
    },
    Import: {
        id: 'Import',
        name: 'Importer fil fra ByggSøk',
        finished: false,
        hasErrors: false
    },
    NextProcessCategory: {
        id: 'NextProcessCategory',
        name: 'Velg søknadstype',
        finished: false,
        hasErrors: false
    },
    Receipt: {
        id: 'Receipt',
        name: 'Velg søknadssystem',
        finished: false,
        hasErrors: false
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_WIZARD_STEPS:
            return action.payload;
        case UPDATE_WIZARD_STEP:
            return action.payload
        default:
            return state;
    }

}
