// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Stylesheets
import style from 'components/partials/ReporteeInfo.module.scss';

class ReporteeInfo extends Component {
  render() {
      const selectedReportee = this.props.selectedReportee;
    return selectedReportee && Object.keys(selectedReportee).length
        ? (<div className={style.reporteeInfo}>
                <span>Du representer {selectedReportee.Name}</span>
            </div>)
        : '';
  }
}

const mapStateToProps = state => ({
    selectedReportee: state.selectedReportee
  });
  

export default connect(mapStateToProps, null)(ReporteeInfo);
