// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// DIBK Design
import { Header, Button } from 'dibk-design';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

// Assets
import byggsokSaveAsIcon from 'assets/images/byggsokSaveAsIcon.png';

// Stylesheets
import commonStyle from 'components/routes/Wizard/common.module.scss';

class SignIn extends Component {
    render() {
console.log(byggsokSaveAsIcon);

        const returnUrl = `${window.location.origin}/AvailableReportees${window.location.search}`;
        return (<div>
            <div className={commonStyle.introText}>
                <Header content="Få hjelp til å konvertere søknader" />
                <div className={commonStyle.ingress}>
                    <p>I oktober 2020 stengte ByggSøk for nye søknader og erstattes med nye løsninger.</p>
                    <p>Her får du hjelp til å konvertere innsendte byggesøknader fra ByggSøk slik at du kan overføre og sende inn neste delsøknad i ett av de nye søknadssystemene.</p>

                    <p>Merk! Veiviseren skal brukes dersom du allerede har sendt inn en søknad til kommunen. Den hjelper deg med å hente ut informasjon som kan gjenbrukes i neste delsøknad.</p>
                    <p>Eksempel: Du har sendt en rammesøknad til kommunen. Da kan denne veiviseren hjelpe deg med å opprette en søknad om igangssettingstillatelse i et av de nye søknadssystemene.</p>

                    <p>Se en oversikt over <a href="https://dibk.no/tjenestene">de nye løsningene her</a>. </p>
                </div>
                <h2>Før du begynner</h2>
                <p>Før du begynner, må du finne filen du ønsker å konvertere. Filen må være lagret i xml-format på din maskin.</p>
                <p>ByggSøk stengte 1. januar 2022, og det er ikke lenger mulig å eksportere filer fra ByggSøk. Du kan derfor bare bruke veiviseren dersom du har hentet ut xml-filen før ByggSøk stengte.</p>

                <div className={commonStyle.buttonRow}>
                    <a href={`${getEnvironmentVariable('altinnServer')}/Pages/ExternalAuthentication/Redirect.aspx?returnUrl=${returnUrl}`}>
                        <Button color="primary" content="Logg inn i Altinn" />
                    </a>
                </div>
            </div>
        </div>)
    }
}

export default connect(null, null)(SignIn);
