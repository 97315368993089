import { UPDATE_SELECTED_NEXT_PROCESS_CATEGORY } from 'constants/types';

export const updateSelectedNextProcessCategory = nextProcessCategory => dispatch => {
    return dispatch(
        {
            type: UPDATE_SELECTED_NEXT_PROCESS_CATEGORY,
            payload: nextProcessCategory
        }
    )
};
