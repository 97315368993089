// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// DIBK Design
import { Header, RadioButtonListItem, Button, Paper, InputField } from 'dibk-design';

// Actions
import { updateWizardSteps } from 'actions/WizardStepsActions';
import { postConvertedAttachment } from 'actions/AttachmentActions';
import { getProjectAddress, postReceiptEmail } from 'actions/ReceiptActions';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

// Stylesheets
import commonStyle from 'components/routes/Wizard/common.module.scss';


class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedApplicationSystem: null,
            applicationSystems: null,
            messageIsPosted: false,
            error: null,
            email: '',
            emailIsValid: false,
            emailReceiptIsSent: false
        };
        this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
    }

    componentDidMount() {

        const hasSelectedReportee = this.props.selectedReportee && Object.keys(this.props.selectedReportee).length;
        const hasConvertedAttachment = this.props.convertedAttachment && Object.keys(this.props.convertedAttachment).length;
        if (hasSelectedReportee && hasConvertedAttachment) {
            this.props.postConvertedAttachment(this.props.convertedAttachment, this.props.selectedReportee).then((error) => {
                if (error) {
                    this.setError(error);
                }
                this.setState({
                    messageIsPosted: true
                });
            });
        }

    }

    componentDidUpdate(prevProps) {
        const hasSelectedReportee = this.props.selectedReportee && Object.keys(this.props.selectedReportee).length;
        const hadSelectedReportee = prevProps.selectedReportee && Object.keys(prevProps.selectedReportee).length;
        const hasConvertedAttachment = this.props.convertedAttachment && Object.keys(this.props.convertedAttachment).length;
        const hadConvertedAttachment = prevProps.convertedAttachment && Object.keys(prevProps.convertedAttachment).length;

        const selectedReporteeHasChanged = hasSelectedReportee && !hadSelectedReportee;
        const convertedAttachmentHasChanged = hasConvertedAttachment && !hadConvertedAttachment;
        const hasChanges = selectedReporteeHasChanged || convertedAttachmentHasChanged;

        if (hasChanges && !this.state.messageIsPosted) {
            this.props.postConvertedAttachment(this.props.convertedAttachment, this.props.selectedReportee).then((error) => {
                if (error) {
                    this.setError(error);
                }
                this.setState({
                    messageIsPosted: true
                });
            });
        } else if (this.state.messageIsPosted) {
            this.setApplicationSystems(this.props.selectedReportee.ReporteeId, this.props.messageId);
        }
    }

    autoSelectApplicationSystemFromUrlParam() {
        const applicationSystemParam = new URL(window.location.href).searchParams.get("applicationSystem");
        if (applicationSystemParam) {
            const selectedApplicationSystem = {
                ...this.state.applicationSystems[applicationSystemParam],
                id: applicationSystemParam
            };
            this.setState({
                selectedApplicationSystem: selectedApplicationSystem
            });
        }
    }

    setApplicationSystems(reporteeId, messageId) {
        if (!this.state.applicationSystems) {
            const applicationUrls = getEnvironmentVariable('applicationUrls') ? getEnvironmentVariable('applicationUrls') : null;
            if (applicationUrls) {
                this.setState({
                    applicationSystems: {
                        byggesoknaden: {
                            url: applicationUrls.byggesoknaden ? `${applicationUrls.byggesoknaden}?AR=${messageId}&bruker=${reporteeId}` : '',
                            name: 'Byggesøknaden.no fra Ambita og Norconsult'
                        },
                        ebyggesok: {
                            url: applicationUrls.ebyggesok ? `${applicationUrls.ebyggesok}?AR=${messageId}&bruker=${reporteeId}` : '',
                            name: 'eByggesøk fra Norkart'
                        },
                        holte: {
                            url: applicationUrls.holte ? `${applicationUrls.holte}?AR=${messageId}&bruker=${reporteeId}` : '',
                            name: 'Holte ByggSøk'
                        },
                        byggsok: {
                            url: applicationUrls.byggsok ? `${applicationUrls.byggsok}?AR=${messageId}&bruker=${reporteeId}` : '',
                            name: 'MAKS-søk fra Arkitektbedriftene'
                        },
                        rorweb: {
                            url: `https://www.rorweb.no/om-rorweb/ImporterSoknad?AR=${messageId}&bruker=${reporteeId}`,
                            name: 'RørWeb fra Rørentreprenørene'
                        },
                        oslokommune: {
                            url: `https://www.oslo.kommune.no/plan-bygg-og-eiendom/skal-du-bygge-rive-eller-endre/byggesoknad-for-fagfolk/ImporterSoknad?AR=${messageId}&bruker=${reporteeId}`,
                            name: 'Byggesøknad for fagfolk fra Oslo kommune'
                        },
                        notDecided: {
                            url: null,
                            name: 'Ikke bestemt meg'
                        }
                    }
                }, () => { this.autoSelectApplicationSystemFromUrlParam() });
            }
        }
    }

    setError(error) {
        this.setState({
            error
        });
        let wizardSteps = {
            ...this.props.wizardSteps
        };
        if (wizardSteps && wizardSteps['NextProcessCategory']) {
            wizardSteps['NextProcessCategory'] = {
                ...wizardSteps['NextProcessCategory'],
                finished: false,
                hasErrors: true
            };
            this.props.updateWizardSteps(wizardSteps);
        }
    }

    handlePreviousButtonClick() {
        if (this.props.wizardSteps && this.props.wizardSteps['NextProcessCategory']) {
            let wizardSteps = { ...this.props.wizardSteps };
            wizardSteps['NextProcessCategory'].finished = false;
            this.props.updateWizardSteps(wizardSteps);
        }
    }

    handleRadioButtonChange(applicationSystem) {
        this.setState({
            selectedApplicationSystem: applicationSystem
        });
    }

    handleEmailInputChange(event) {
        const email = event && event.target && event.target.value ? event.target.value : '';
        const emailIsValid = this.validateEmailField(email);
        this.setState({
            email,
            emailIsValid
        });
    }

    validateEmailField(email) {
        const regex = /(?:[æøåa-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[æøåa-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[æøåa-z0-9](?:[æøåa-z0-9-]*[æøåa-z0-9])?\.)+[æøåa-z0-9](?:[æøåa-z0-9-]*[æøåa-z0-9])?)/gm;
        return regex.exec(email) !== null;
    }

    renderRadioButtonList(applicationSystems) {
        return applicationSystems && Object.keys(applicationSystems).length
            ? Object.keys(applicationSystems).map(applicationSystemId => {
                let applicationSystem = applicationSystems[applicationSystemId];
                applicationSystem = { ...applicationSystem, id: applicationSystemId };
                return (<RadioButtonListItem onChange={() => { this.handleRadioButtonChange(applicationSystem) }} key={applicationSystemId} inputValue={applicationSystemId} id={applicationSystemId} name="selectedApplicationSystem" checked={this.state.selectedApplicationSystem && this.state.selectedApplicationSystem.id === applicationSystemId}>
                    {applicationSystem.name}
                </RadioButtonListItem>);
            }) : '';
    }

    getMessageUrl(messageId) {
        return messageId ? `${getEnvironmentVariable('altinnServer')}/Pages/ServiceEngine/FormsEngine/FillInForm.aspx?ReporteeElementID=${messageId}` : '';
    }

    getSelectedNextProcessCategoryName(processCategories, selectedNextProcessCategory) {
        const processCategory = processCategories && processCategories.length && selectedNextProcessCategory
            ? processCategories.find(processCategory => {
                return selectedNextProcessCategory === processCategory.id;
            }) : null;
        return processCategory && processCategory.name ? processCategory.name : '';
    }

    postEmail(messageId, email, selectedReportee, processCategories, selectedNextProcessCategory, convertedAttachment) {
        const subForm = this.getConvertedSubForm(convertedAttachment);

        this.props.getProjectAddress(subForm).then(projectAddress => {
            const selectedNextProcessCategoryName = this.getSelectedNextProcessCategoryName(processCategories, selectedNextProcessCategory);
            const subject = `Søknaden fra ByggSøk er konvertert: ${selectedNextProcessCategoryName} fra ${projectAddress}`;
            const purpose = `${selectedNextProcessCategoryName} for ${projectAddress}`;
            const requestBody = {
                email,
                emailSenderName: selectedReportee.Name,
                subject,
                purpose,
                altinArchiveReferenceNumber: `a${messageId}`,
                messageUrl: this.getMessageUrl(messageId)
            };
            this.props.postReceiptEmail(requestBody).then(() => {
                this.setState({
                    emailReceiptIsSent: true
                });
            });
        })
    }

    getConvertedSubForm(convertedAttachment) {
        const forms = convertedAttachment && convertedAttachment._embedded && convertedAttachment._embedded.forms && convertedAttachment._embedded.forms.length ? convertedAttachment._embedded.forms : null;
        return forms ? forms.find(form => {
            
            return form.dataFormatId === "6146" && form.dataFormatVersion === "44096";
        }) : null;
    }

    renderErrorText = (error) => {
        switch (error.status) {
            case 401:
                return (
                    <div>
                        <p>
                            Du har nok ikke rettigheter for valgt firma i Altinn til å konvertere søknaden. Vi anbefaler at firmaet ditt delegerer rollen “Plan- og byggesak” for å gi ansatte tilgang til å opprette byggesøknader i Altinn.
                        </p>
                        <p>
                        Se <a href="https://dibk.no/verktoy-og-veivisere/andre-fagomrader/fellestjenester-bygg/slik-gir-du-rettigheter-til-byggesak-i-altinn/">
                                veiviser for roller og rettigheter til byggesak i Altinn
                            </a>  for mer informasjon.
                        </p>
                    </div>
                );
            default:
                return error?.statusText || '';
        }
    }

    renderMessageLink(messageId) {
        const messageUrl = this.getMessageUrl(messageId);
        return messageUrl
            ? (
                <div>
                    <p>
                        Søknaden fra ByggSøk er konvertert. Velg søknadssystemet du vil bruke til å fullføre søknaden fra listen under. Du kan også gjøre dette senere i et av søknadssystemene.
                    </p>
                    <p>
                        Søknaden er lagret i Altinn, i meldingsboksen til den du valgte å representere i steg 2. Referanse i Altinn er <a href={messageUrl}>a{messageId}</a>.
                    </p>
                </div>
            )
            : ''
    }

    renderApplicationSystemInfo(selectedApplicationSystem) {
        const selectedApplicationSystemId = selectedApplicationSystem && selectedApplicationSystem.id ? selectedApplicationSystem.id : null;
        switch (selectedApplicationSystemId) {
            case "rorweb":
            case "oslokommune":
            case "ebyggesok":
                return (
                    <Paper>
                        <p>
                            Dette systemet tilbyr ikke konvertering fra ByggSøk.
                        </p>
                        <p>
                            For å få sjekket om du kan få med deg informasjonen fra byggesaken inn i dette systemet, må du kontakte leverandøren.
                        </p>
                    </Paper>)
            case "notDecided":
                return (
                    <Paper>
                        <p>
                            Den konverterte søknaden er lagret i Altinn, og du kan hente den frem senere i et av de nye søknadssystemene.
                        </p>
                        <p>
                            Les mer om hvilke søknadsløsninger du kan velge her: <a href="https://dibk.no/tjenestene">dibk.no/tjenestene</a>.
                        </p>
                    </Paper>)
            default:
                return '';
        }
    }

    applicationSystemButtonIsDisabled(selectedApplicationSystem) {
        const selectedApplicationSystemId = selectedApplicationSystem && selectedApplicationSystem.id ? selectedApplicationSystem.id : null;
        switch (selectedApplicationSystemId) {
            case "rorweb":
            case "oslokommune":
            case "ebyggesok":
            case "notDecided":
                return true
            default:
                return false;
        }
    }

    render() {
        const error = this.state.error;

        return error && Object.keys(error).length
            ? (<React.Fragment>
                <Header content="Velg søknadssystem" />
                { this.renderErrorText(error)}
                <Link to="NextProcessCategory">
                    <Button color="primary" content="Forrige" arrow='left' onClick={this.handlePreviousButtonClick} />
                </Link>
                
                { /* eslint-disable-next-line */ }
                <a href="#"> 
                    <Button color="primary" disabled={this.state.selectedApplicationSystem ? '' : 'disabled'} content="Neste" />
                { /* eslint-disable-next-line */ }
                </a>

            </React.Fragment>)
            : (<React.Fragment>
                <Header content="Velg søknadssystem" />

                {this.state.messageIsPosted
                    ? (<React.Fragment>
                        <Paper>
                            <div className={commonStyle.introText}>
                                {this.renderMessageLink(this.props.messageId)}
                            </div>
                            {this.renderRadioButtonList(this.state.applicationSystems)}
                        </Paper>
                        {this.renderApplicationSystemInfo(this.state.selectedApplicationSystem)}
                        <Paper>
                            <div className={commonStyle.textAlignCenter}>
                                <Header size={2} content="Ønsker du kvittering på e-post?" />
                                {
                                    this.state.emailReceiptIsSent
                                        ? (<p>Kvittering er sendt på e-post til {this.state.email}</p>)
                                        : (<React.Fragment>
                                            <div className={commonStyle.inlineInputFields}>
                                                <InputField type="email" value={this.state.email} onChange={event => this.handleEmailInputChange(event)} id="email" label="Din e-postadresse" />
                                            </div>
                                            <Button size="small"
                                                color={this.state.emailIsValid ? 'primary' : 'default'}
                                                disabled={this.state.emailIsValid ? '' : 'disabled'}
                                                content="Send meg kvittering"
                                                onClick={() => this.postEmail(this.props.messageId, this.state.email, this.props.selectedReportee, this.props.processCategories, this.props.selectedNextProcessCategory, this.props.convertedAttachment)} />
                                        </React.Fragment>)
                                }

                            </div>
                        </Paper>
                    </React.Fragment>
                    )
                    : <Paper><p>Sender inn søknad...</p></Paper>
                }
                <div className={commonStyle.buttonRow}>
                    <Link to={{ pathname: 'NextProcessCategory', search: window.location.search }}>
                        <Button color="primary" content="Forrige" arrow='left' onClick={this.handlePreviousButtonClick} />
                    </Link>
                    <a href={this.state.selectedApplicationSystem ? this.state.selectedApplicationSystem.url : '#'} target='_blank' rel="noopener noreferrer">
                        <Button color="primary"
                            disabled={!this.state.selectedApplicationSystem || this.applicationSystemButtonIsDisabled(this.state.selectedApplicationSystem) ? 'disabled' : ''}
                            content="Gå til leverandør" />
                    </a>
                </div>
            </React.Fragment>)
    }
}

const mapStateToProps = state => ({
    wizardSteps: state.wizardSteps,
    selectedReportee: state.selectedReportee,
    convertedAttachment: state.convertedAttachment,
    messageId: state.messageId,
    processCategories: state.processCategories,
    selectedNextProcessCategory: state.selectedNextProcessCategory
});

const mapDispatchToProps = {
    updateWizardSteps,
    postConvertedAttachment,
    getProjectAddress,
    postReceiptEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
