// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';
import { getStatusError } from 'helpers/errorMessageHelpers';

// Constants
import { UPDATE_ATTACHMENT, UPDATE_CONVERTED_ATTACHMENT, UPDATE_MESSAGE_ID } from 'constants/types';

export const updateAttachment = attachment => dispatch => {
    return dispatch(
        {
            type: UPDATE_ATTACHMENT,
            payload: attachment
        }
    )
};

export const convertAttachment = attachment => (dispatch, getState) => {
    const apiUrl = `${getEnvironmentVariable('byggsokApi')}/ConvertToFTB`;
    const nextProcessCategory = getState() && getState().selectedNextProcessCategory ? getState().selectedNextProcessCategory : '';
    const applicationSystemParam = new URL(window.location.href).searchParams.get("applicationSystem");
    const defaultApplicationSystemValue = "FraVeiviseren";
    const applicationSystemValue = applicationSystemParam && applicationSystemParam.length ? applicationSystemParam : defaultApplicationSystemValue;
    let body = {
        xml: attachment,
        nesteProsess: nextProcessCategory
    };
    body.applicationSystem = applicationSystemValue;
    const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl, fetchOptions).then(
        res => res.json()).then(
            convertedAttachment => dispatch(
                {
                    type: UPDATE_CONVERTED_ATTACHMENT,
                    payload: convertedAttachment
                }
            )
        )
};

const getMessageIdFromLocationUrl = locationUrl => {
    return locationUrl.match(/[^/a]*$/ig)[0];
}

export const postConvertedAttachment = (convertedAttachment, selectedReportee) => dispatch => {
    if (convertedAttachment && selectedReportee) {
        const apiUrl = selectedReportee._links && selectedReportee._links.messages && selectedReportee._links.messages.href ? `${selectedReportee._links.messages.href}?complete=false` : null;
        const fetchOptions = {
            method: 'POST',
            headers: {
                'ApiKey': getEnvironmentVariable('altinnApiKey'),
                'Content-Type': 'application/hal+json',
            },
            xhrFields: {
                withCredentials: true
            },
            credentials: "include",
            body: JSON.stringify(convertedAttachment)
        };
        return fetch(apiUrl, fetchOptions).then(
            response => {
                if (response.status !== 201) {
                    return getStatusError(response);
                } else {
                    let location = null;
                    response.headers.forEach((headerValue, headerKey) => {
                        if (headerKey === 'location') {
                            location = headerValue;
                        }
                    });
                    dispatch(
                        {
                            type: UPDATE_MESSAGE_ID,
                            payload: getMessageIdFromLocationUrl(location)
                        }
                    )
                }
            })
    }
};
