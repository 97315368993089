// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// DIBK Design
import { Header, DragAndDropFileInput, Button, Paper } from 'dibk-design';

// Actions
import { updateAttachment } from 'actions/AttachmentActions';
import { updateWizardSteps } from 'actions/WizardStepsActions';

// Helpers
import { removeLineBreaks, removeTabCharacters } from 'helpers/transformationHelpers';

// Stylesheets
import commonStyle from 'components/routes/Wizard/common.module.scss';

class Import extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasSelectedFile: false,
            selectedFileName: null
        }
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
        this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
    }

    handleNextButtonClick() {
        if (this.props.wizardSteps && this.props.wizardSteps['Import']) {
            let wizardSteps = { ...this.props.wizardSteps };
            wizardSteps['Import'].finished = true;
            this.props.updateWizardSteps(wizardSteps);
        }
    }

    handlePreviousButtonClick() {
        if (this.props.wizardSteps && this.props.wizardSteps['AvailableReportees']) {
            let wizardSteps = { ...this.props.wizardSteps };
            wizardSteps['AvailableReportees'].finished = false;
            this.props.updateWizardSteps(wizardSteps);
        }
    }

    handleFileInputChange(files) {
        const file = files[0];
        if (file) {
            this.setState({
                selectedFileName: file.name
            });
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt) {
                let fileContent = evt.target.result;
                fileContent = removeLineBreaks(fileContent);
                fileContent = removeTabCharacters(fileContent);
                this.props.updateAttachment(fileContent);
            }.bind(this)
            reader.onerror = function (evt) {
                console.warn("error reading file");
            }
        }
        this.setState({
            hasSelectedFile: true
        });
    }

    render() {
        return (<div>
            <Header content="Importer fil fra ByggSøk" />
            <Paper>
                <div className={commonStyle.introText}>
                    <p>Velg «Legg til fil» og finn fram XML-filen du har lastet ned fra ByggSøk.</p>
                </div>
                <div className={commonStyle.fileInputContainer}>
                    <DragAndDropFileInput id="fileImport" 
                                          label="Byggsøk XML-fil" 
                                          onDragAndDropChange={this.handleFileInputChange} 
                                          onSelectChange={(event) => this.handleFileInputChange(event.target.files)}
                                          selectedFileName={this.state.selectedFileName} 
                                          buttonColor="primary" 
                                          buttonContent={this.state.hasSelectedFile ? 'Last opp annen ByggSøk XML-fil' : 'Last opp ByggSøk XML-fil'} />
                </div>
            </Paper>
            <div className={commonStyle.buttonRow}>
                <Link to={{pathname: 'AvailableReportees', search: window.location.search}}>
                    <Button color="primary" content="Forrige" onClick={this.handlePreviousButtonClick} arrow='left' />
                </Link>
                <Link to={{pathname: 'NextProcessCategory', search: window.location.search}}>
                    <Button color="primary" disabled={this.state.hasSelectedFile ? '' : 'disabled'} content="Neste" arrow='right' onClick={this.handleNextButtonClick} />
                </Link>
            </div>
        </div>)
    }
}

const mapStateToProps = state => ({
    attachment: state.attachment,
    wizardSteps: state.wizardSteps
});

const mapDispatchToProps = {
    updateAttachment,
    updateWizardSteps
};

export default connect(mapStateToProps, mapDispatchToProps)(Import);
