import { UPDATE_MESSAGE_ID } from 'constants/types';

const initialState = "";

export default function(state = initialState, action) {
	switch(action.type) {
		case UPDATE_MESSAGE_ID:
			return action.payload;
		default:
			return state;
	}

}
