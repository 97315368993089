// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

// Components
import WizardNavigation from 'components/partials/WizardNavigation';
import SignIn from 'components/routes/Wizard/SignIn';
import AvailableReportees from 'components/routes/Wizard/AvailableReportees';
import Import from 'components/routes/Wizard/Import';
import NextProcessCategory from 'components/routes/Wizard/NextProcessCategory';
import Receipt from 'components/routes/Wizard/Receipt';

// Actions
import { initWizardSteps } from 'actions/WizardStepsActions';

// Stylesheets
import style from 'components/routes/Wizard.module.scss';

class Wizard extends Component {

  componentDidMount(){
    // Warning before leaving the page (back button, or outgoinglink)
    window.onbeforeunload = function() {
      const pathname = window.location.pathname.toLowerCase();
      if (pathname.toLowerCase() === '/signin' || pathname.toLowerCase() === '/'){
        return;
      }else {
        return "";
      }
    };
  }

  
  render() {
    const activeStepId = this.props.match.params.activeStepId ?? 'SignIn';
    return this.props.wizardSteps && Object.keys(this.props.wizardSteps).length
      ? (<div className={style.container}>
        <WizardNavigation steps={this.props.wizardSteps} activeStepId={activeStepId} />
        <div className={style.contentContainer}>
          <Switch>
            <Route exact={true} path="/SignIn" render={(props) => (<SignIn {...props} />)} />
            <Route exact={true} path="/AvailableReportees" render={(props) => (<AvailableReportees {...props} />)} />
            <Route exact={true} path="/Import" render={(props) => (<Import {...props} />)} />
            <Route exact={true} path="/NextProcessCategory" render={(props) => (<NextProcessCategory {...props} />)} />
            <Route exact={true} path="/Receipt" render={(props) => (<Receipt {...props} />)} />
            <Route exact={true} path="/" render={(props) => (<SignIn {...props} />)} />
          </Switch>
        </div>
      </div>)
      : '';
  }
}

const mapStateToProps = state => ({
  wizardSteps: state.wizardSteps
});

const mapDispatchToProps = {
  initWizardSteps
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
