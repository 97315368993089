import { INIT_WIZARD_STEPS, UPDATE_WIZARD_STEP } from 'constants/types';

export const initWizardSteps = wizardSteps => dispatch => {
    return dispatch(
        {
            type: INIT_WIZARD_STEPS,
            payload: wizardSteps
        }
    )
};

export const updateWizardSteps = wizardSteps => (dispatch) => {
    return dispatch(
        {
            type: UPDATE_WIZARD_STEP,
            payload: wizardSteps
        }
    )
};