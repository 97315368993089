import { UPDATE_SELECTED_REPORTEE } from 'constants/types';

const initialState = {};

export default function(state = initialState, action) {
	switch(action.type) {
		case UPDATE_SELECTED_REPORTEE:
			return action.payload;
		default:
			return state;
	}

}
