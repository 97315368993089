const initialState = [
    {
        id: 'MB',
        name: 'Søknad om midlertidig brukstillatelse'
    },
    {
        id: 'FA',
        name: 'Søknad om ferdigattest'
    },
    {
        id: 'IG',
        name: 'Søknad om igangsettingstillatelse (gjelder kun for rammesøknader)'
    },
    {
        id: 'ES',
        name: 'Søknad om endring av tillatelse'
    }
];

export default function(state = initialState, action) {
	switch(action.type) {
		default:
			return state;
	}

}

