import { UPDATE_CONVERTED_ATTACHMENT } from 'constants/types';

const initialState = {}

export default function(state = initialState, action) {
	switch(action.type) {
		case UPDATE_CONVERTED_ATTACHMENT:
			return action.payload;
		default:
			return state;
	}

}
